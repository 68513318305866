/* eslint-disable react/no-danger */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable no-param-reassign */
import React from 'react';
import { graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import { Breadcrumb } from 'gatsby-plugin-breadcrumb';

import * as styles from '../css/templates/Blog.module.scss';

import SEO from '../components/layout/SEO';
import Layout from '../components/layout/Layout';
import Author from '../components/author/Author';

import { formatDate } from '../util';

import ClockIcon from '../images/icons/clock.svg';

const Template = ({ data, pageContext }) => {
    const { markdownRemark } = data; // data.markdownRemark holds blog data
    const { frontmatter, html, timeToRead } = markdownRemark;

    const {
        breadcrumb: { crumbs },
    } = pageContext;

    function capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    crumbs.map((crumb) => {
        crumb.crumbLabel = crumb.crumbLabel.replace(/-/g, ' ');
        crumb.crumbLabel = capitalizeFirstLetter(crumb.crumbLabel);
        return crumb;
    });

    return (
        <>
            <SEO
                title={frontmatter.title}
                description={frontmatter.seoDescription}
                path={frontmatter.path}
                image={
                    frontmatter.featuredimage.childImageSharp.gatsbyImageData
                        .src
                }
                schemaData={[getSchemaData(frontmatter)]}
                keywords={frontmatter.seoKeywords}
                allowIndexing={frontmatter.publish}
            />
            <Layout mainClass={styles.Blog}>
                <div className={styles.breadcrumbContainer}>
                    <Breadcrumb crumbs={crumbs} crumbSeparator=" / " />
                </div>
                <article className={styles.blogContainer}>
                    <section className={styles.blogHeader}>
                        <div className={styles.info}>
                            <span className={styles.category}>
                                {frontmatter.category}
                            </span>
                            <ClockIcon
                                alt="clock icon"
                                className={styles.clockIcon}
                            />
                            <span className={styles.timeToRead}>
                                {timeToRead} min read
                            </span>
                        </div>
                        <div>
                            <time dateTime={frontmatter.date}>
                                {formatDate(frontmatter.date)}
                            </time>
                        </div>
                    </section>
                    <header>
                        <h1>{frontmatter.title}</h1>
                    </header>
                    <section className={styles.blogContent}>
                        <GatsbyImage
                            image={
                                frontmatter.featuredimage.childImageSharp
                                    .gatsbyImageData
                            }
                            alt=""
                        />
                        <div
                            className={styles.content}
                            dangerouslySetInnerHTML={{ __html: html }}
                        />
                        <hr />
                    </section>
                    <footer>
                        <Author authorName={frontmatter.author} />
                        <p>
                            Article updated on:{' '}
                            {formatDate(frontmatter.lastUpdated)}
                        </p>
                    </footer>
                </article>
            </Layout>
        </>
    );
};

export const pageQuery = graphql`
    query ($path: String!) {
        markdownRemark(frontmatter: { path: { eq: $path } }) {
            html
            timeToRead
            frontmatter {
                publish
                seoDescription
                seoKeywords
                title
                date
                lastUpdated
                host
                path
                category
                author
                featuredimage {
                    childImageSharp {
                        gatsbyImageData(
                            placeholder: BLURRED
                            layout: FULL_WIDTH
                        )
                    }
                }
            }
        }
    }
`;

const getSchemaData = (data) => {
    return {
        '@context': 'http://schema.org',
        '@type': 'Article',
        headline: data.title,
        publisher: {
            '@type': 'Organization',
            name: 'Ripples',
            url: 'https://www.joinripples.org/',
            logo: {
                '@type': 'ImageObject',
                url: 'https://roundups-marketing-site.s3.eu-west-1.amazonaws.com/images/ripples-icon-white-bg.png',
                width: 500,
                height: 500,
                caption: 'Ripples',
            },
        },
        mainEntityOfPage: {
            '@type': 'WebPage',
            '@id': data.path,
        },
        author: {
            '@type': 'Person',
            name: data.author,
        },
        datePublished: data.date,
        dateModified: data.lastUpdated,
        image: `${data.host}${data.featuredimage.childImageSharp.gatsbyImageData.src}`,
    };
};

export default Template;
