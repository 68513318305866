// extracted by mini-css-extract-plugin
export var Blog = "Blog-module--Blog--gGtZ+";
export var blogContainer = "Blog-module--blogContainer--hjA0-";
export var blogContent = "Blog-module--blogContent--R-jFY";
export var blogHeader = "Blog-module--blogHeader--ArpJD";
export var breadcrumbContainer = "Blog-module--breadcrumbContainer--q-JoQ";
export var category = "Blog-module--category--yR3Bl";
export var clockIcon = "Blog-module--clockIcon--XiNd8";
export var content = "Blog-module--content--m93vU";
export var info = "Blog-module--info--SsTqH";
export var timeToRead = "Blog-module--timeToRead--KotnN";