import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

import * as styles from './Author.module.scss';

const Author = ({ authorName }) => {
    const data = useStaticQuery(graphql`
        {
            allMarkdownRemark(
                filter: { fileAbsolutePath: { regex: "/(authors)/" } }
            ) {
                edges {
                    node {
                        frontmatter {
                            fullName
                            image {
                                childImageSharp {
                                    gatsbyImageData(layout: FULL_WIDTH)
                                }
                            }
                            blurb
                        }
                    }
                }
            }
        }
    `);

    const { edges: authors } = data.allMarkdownRemark;

    const renderAuthor = () => {
        const author = authors.find(
            (author) => author.node.frontmatter.fullName === authorName,
        ).node.frontmatter;

        return (
            <aside className={styles.Author}>
                <div className={styles.authorDetails}>
                    <div className={styles.imageContainer}>
                        <GatsbyImage
                            image={author.image.childImageSharp.gatsbyImageData}
                            className={styles.image}
                            alt={author.fullName}
                        />
                    </div>
                    <p>
                        Words by{' '}
                        <span
                            itemProp="author"
                            itemScope
                            itemType="http://schema.org/Person">
                            <span itemProp="name">{author.fullName}</span>
                        </span>
                    </p>
                </div>
                <p className={styles.blurb}>{author.blurb}</p>
            </aside>
        );
    };

    return renderAuthor();
};

export default Author;
